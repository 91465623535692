<template>
  <div class="container">
    <div ref="navbar">
      <NavBar :msg="videoInfo.v_name || ''" openLeft="1" />
    </div>

    <div class="video" ref="video" :style="{ height: AliyunHeight + 'rem' }">
      <div class="flag" ref="flag"></div>
      <AliyunPlayer v-if="flag" :chartData="AliyunJson" />
    </div>

    <section class="sectionbox" ref="sectionbox">
      <div class="videoinfo">
        <div class="tit">{{ videoInfo.v_name }}</div>
        <div class="hint" v-html="videoInfo.v_keywords"></div>
      </div>

      <div class="videofunction">
        <div class="boxes">
          <!-- vPraise -->
          <div
            class="item icon-like"
            :class="vPraise == 1 ? 'active' : ''"
            @click="btnsLike()"
          >
            <i></i>
            <span>{{ praiseSum }}</span>
          </div>
        </div>
        <div class="boxes">
          <div class="item icon-chat" @click="begincomment()">
            <span>{{ +videoInfo.comment_sum + numAdd }}</span>
            <i></i>
          </div>
          <div
            class="item icon-love"
            :class="collectionType == 1 ? 'active' : ''"
            @click="btnLove()"
          >
            <i></i>
          </div>
          <!-- <div class="item icon-share" @click="share()">
            <i></i>
          </div> -->
        </div>
      </div>

      <CommentList
        ref="child1"
        :Newlist="commentmain"
        :id="this.$route.params.id"
        :live="this.$route.params.islive"
        @parentEvent="toClick"
      />
    </section>
    <Share ref="child" />

    <div
      ref="begincomment"
      @click="begincomment()"
      v-show="isShow"
      class="begincomment"
    >
      <div class="bc">
        <p>
          全部热评<strong>{{ +videoInfo.comment_sum + numAdd }}</strong>
        </p>
        <i class="icon-pencil"></i>
      </div>
    </div>
    <van-overlay :show="show">
      <button class="bugOverlay" @click="btnsoverlay()"></button>
    </van-overlay>
    <transition name="van-slide-up">
      <div v-show="isToShow" ref="publishcomment" class="publishcomment">
        <span class="tit">发表评论</span>
        <textarea
          placeholder="我来说几句"
          name=""
          id="inputId"
          v-model="enterText"
          ref="inputVal"
        ></textarea>
        <div class="boxes">
          <button @click="publish()" class="btn">发表</button>
        </div>
      </div>
    </transition>
    <van-popup
      round
      closeable
      close-icon="close"
      v-model:show="popshow"
      position="bottom"
      :style="{ height: '20%' }"
      class="loginmod"
    >
      <p>您还没有登录</p>
      <button @click="login()">登录</button>
    </van-popup>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import AliyunPlayer from "@/components/AliyunPlayer.vue";
import CommentList from "@/components/CommentList.vue";

import Share from "@/components/Share.vue";
import { Toast } from "vant";
import { videochannelinfo, commentAdd, likelollection } from "@/api/api";

export default {
  name: "LandscapeVideo",
  components: {
    AliyunPlayer,
    NavBar,
    Share,
    CommentList,
  },
  props: {
    msg: String,
    newIslive: String,
  },
  data() {
    return {
      collectionType: 0,
      vPraise: 0,
      praiseSum: 0,
      numAdd: 0,
      loginCode: 0,
      popshow: false,
      flag: false,
      isReady: false,
      show: false,
      isShow: true,
      isToShow: false,
      commentmain: [], //评论内容加载
      commentCount: 0,
      enterText: "",
      videoInfo: [],
      AliyunJson: [],
      fullHeight: 0, // 可视区域高度
      AliyunHeight: 5.6,
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute(
        "style",
        "position: fixed;top: 0;left: 0;right: 0;bottom: 0;width: 100%;margin: 0 auto;overflow: hidden;"
      );
  },
  methods: {
    toClick(msg) {
      this.popshow = msg;
    },
    share() {
      this.$refs.child.childData = true;
      console.log(this);
    },
    login() {
      window.location.href = "https://m.sctx.com/login.html";
    },
    begincomment() {
      this.isShow = false;
      this.isToShow = true;
      this.show = true;
      this.$nextTick(() => {
        this.enterText = "";
        this.$refs.inputVal.focus();
      });
    },
    btnsoverlay() {
      this.isShow = true;
      this.isToShow = false;
      this.show = false;
    },
    publish() {
      this.isShow = true;
      this.isToShow = false;
      this.show = false;
      let data = {
        data: `{"v_c_content":"${this.enterText}","is_live":"${this.$route.params.islive}","v_c_v_id":"${this.videoInfo.v_id}"}`,
      };
      if (this.enterText == "") {
        return false;
      } else {
        commentAdd(data)
          .then((data) => {
            console.log(data);
            this.loginCode = data.data.code;
            if (data.data.code == 30000) {
              this.popshow = true; //开启登录弹窗
              return false;
            }
            let hq = data.data.data;
            this.numAdd += 1;
            const newChat = {
              v_c_content: hq.v_c_content,
              headimg: hq.user_headimg,
              v_c_id: hq.v_c_id,
              v_c_member_name: hq.member_name,
              v_c_publish_time: hq.v_c_publish_time,
            };

            this.$refs.child1.childMethod(newChat); //调用子组件的函数，并把这个新消息传给子组件。
            this.enterText = "";
            this.$refs.sectionbox.scrollTop = 0; // 内容区域
            window.scrollTo(0, document.body.offsetHeight);
          })
          .catch((err) => console.log("失败了" + err));
      }
    },
    btnLove() {
      let data = {
        data: `{"v_id":"${this.videoInfo.v_id}","click_type":"2","is_live":${this.$route.params.islive}}`,
      };
      const toast = Toast.loading({
        duration: 100000, // 持续展示 toast
        overlay: true,
        forbidClick: true,
        loadingType: "spinner",
        message: "加载中...",
      });
      likelollection(data)
        .then((data) => {
          console.log(data.data.code);
          this.loginCode = data.data.code;
          if (data.data.code == 30000) {
            Toast.clear();
            this.popshow = true; //开启登录弹窗
            return false;
          }
          let Numdata = data.data.data;
          this.collectionType = Numdata.v_collection;

          setTimeout(() => {
            Toast.clear();
          }, 1000);
        })
        .catch((err) => console.log("失败了" + err));
    },
    btnsLike() {
      let data = {
        data: `{"v_id":"${this.videoInfo.v_id}","click_type":"1","is_live":${this.$route.params.islive}}`,
      };
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        overlay: true,
        forbidClick: true,
        message: "加载中...",
      });
      likelollection(data)
        .then((data) => {
          console.log(data.data.code);
          this.loginCode = data.data.code;
          if (data.data.code == 30000) {
            Toast.clear();
            this.popshow = true; //开启登录弹窗
            return false;
          }
          let numData = data.data.data;
          this.vPraise = numData.v_praise;

          if (numData.v_praise == 1) {
            this.praiseSum = +this.praiseSum + 1 * 1;
          } else {
            if (this.praiseSum == 0) {
              this.praiseSum = +this.praiseSum + 1 * 1;
            }
            this.praiseSum = +this.praiseSum - 1 * 1;
          }
          setTimeout(() => {
            Toast.clear();
          }, 1000);
        })
        .catch((err) => console.log("失败了" + err));
    },
    viewingArea() {
      this.fullHeight = `${document.documentElement.clientHeight}`; //默认值
      const allHeight = [
        this.$refs.video,
        this.$refs.navbar,
        this.$refs.begincomment,
      ]
        .map((el) => el.clientHeight)
        .reduce((a, b) => a + b);
      this.$refs.sectionbox.style.height = this.fullHeight - allHeight + "px";
    },
    inputAndroid() {
      //获取原窗口的高度
      let originalHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let _this = this;
      window.onresize = function () {
        //键盘弹起与隐藏都会引起窗口的高度发生变化
        let resizeHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        if (resizeHeight - 0 < originalHeight - 0) {
          //当软键盘弹起，在此处操作
        } else {
          //当软键盘收起，在此处操作

          window.scrollTo(0, document.body.offsetHeight);
        }
      };
    },
    inputIos() {
      // 判断是否是ios
      var ua = navigator.userAgent;
      if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        let flag = false;
        let pageBackNormFunc;
        document.body.addEventListener("focusin", () => {
          // 软键盘弹起事件
          flag = true;
          pageBackNormFunc && clearTimeout(pageBackNormFunc);
        });
        document.body.addEventListener("focusout", () => {
          // 软键盘关闭事件
          if (flag) {
            pageBackNormFunc = setTimeout(function () {
              // 当键盘收起的时候让页面回到原始位置
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }, 200);
          }
          flag = false;
        });
      }
    },
  },
  created() {
    this.inputIos();
    this.inputAndroid();
  },
  updated() {
    this.viewingArea();
  },
  async mounted() {
    this.viewingArea();

    // 接口A···········S
    const dataInfo = {
      data: `{"v_id":${this.$route.params.id},"is_live":${this.$route.params.islive}}`,
    };
    await videochannelinfo(dataInfo)
      .then(({ data: { data } }) => {
        this.videoInfo = data;
        this.AliyunJson = [data.v_img, data.v_video, this.AliyunHeight];
        this.flag = true;
        this.$refs.flag.style.display = "none";
        this.praiseSum = data.praise_sum;
        this.vPraise = data.praise_type;
        this.collectionType = data.collection_type;
      })
      .catch((err) => console.log("失败了" + err));
    // 接口A···········A
  },
};
</script>

<style lang="scss">
.container {
  display: flex;
  flex-direction: column;
}

video {
  width: 100%;
  height: 4rem;
  background: #000;
  overflow: hidden;
}

.videoinfo {
  text-align: left;
  margin: 0 20px;
  padding: 10px 0;
  overflow: hidden;
  .tit {
    font-size: 36px;

    font-weight: bold;
    color: #15181f;
    line-height: 1.4;
  }
  .hint {
    margin-top: 5px;
    font-size: 28px;

    font-weight: 500;
    color: #999;
    line-height: 1.4;
  }
}
.videofunction {
  margin: 20px;
  padding: 15px 0 35px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #f1f2f6 solid;
  .boxes {
    display: flex;
    align-items: center;
    &.boxes:nth-child(1) {
      .item {
        margin-left: 0;
      }
    }
    .item {
      margin-left: 50px;
      i {
        display: block;
      }
      &.icon-like {
        &.active {
          i {
            background: url(../assets/icon/icon-like-active.png) no-repeat;
            background-size: 44px;
          }
          span {
            color: #a91211;
          }
        }
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          background: url(../assets/icon/icon-like.png) no-repeat;
          width: 44px;
          height: 42px;
          background-size: 44px;
        }
        span {
          font-size: 18px;
          font-family: FZLanTingHei-H-GBK;
          font-weight: 400;
          line-height: 1.2;
        }
      }
      &.icon-chat {
        position: relative;
        i {
          background: url(../assets/icon/icon-chat.png) no-repeat;
          width: 42px;
          height: 40px;
          background-size: 42px;
        }
        span {
          position: absolute;
          top: -30px;
          left: 0;
          color: #a91211;
          font-size: 18px;
          font-family: FZLanTingHei-H-GBK;
          font-weight: 400;
          line-height: 1.2;
        }
      }
      &.icon-love {
        &.active {
          i {
            background: url(../assets/icon/icon-love-active.png) no-repeat;
            background-size: 40px;
          }
        }
        i {
          background: url(../assets/icon/icon-love.png) no-repeat;
          width: 44px;
          height: 40px;
          background-size: 44px;
        }
      }
      &.icon-share {
        i {
          background: url(../assets/icon/icon-share.png) no-repeat;
          width: 40px;
          height: 40px;
          background-size: 40px;
        }
      }
    }
  }
}
.begincomment {
  width: 750px;
  height: 90px;
  background: #fff;
  box-shadow: 1px 0px 9px 0px #cecece;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .bc {
    background: #f1f1f1;
    width: 670px;
    height: 70px;
    padding: 0 20px;
    border-radius: 70px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 28px;

      font-weight: bold;
      color: #15181f;
      line-height: 1.2;
      strong {
        margin-left: 5px;
        font-size: 18px;
        font-family: FZLanTingHeiS-DB1-GBK;
        font-weight: 400;
        color: #a91211;
        line-height: 1.2;
      }
    }
    .icon-pencil {
      display: block;
      background: url(../assets/icon/icon-pencil.png) no-repeat;
      width: 32px;
      height: 32px;
      background-size: 32px;
    }
  }
}
.publishcomment {
  text-align: left;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: #fff;
  padding: 0 20px 20px;
  margin: 0 auto;
  box-shadow: 1px 0px 9px 0px #cecece;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 20;
  .tit {
    font-size: 30px;
    line-height: 70px;
    color: #333;
  }
  textarea {
    display: block;
    width: 710px;
    height: 160px;
    padding: 20px;
    font-size: 26px;
    resize: none;
    color: #9a9a9a;
    border: 0;
    background: #f7f8fa;
    overflow: hidden;
    margin: 0;
    box-sizing: border-box;
  }
  .boxes {
    display: flex;
    justify-content: flex-end;
    .btn {
      margin-top: 20px;
      display: block;
      width: 120px;
      height: 60px;
      background: #f7f8fa;
      border: 0;
      font-size: 26px;
      color: #999;
      border-radius: 60px;
    }
  }
}
.sectionbox {
  overflow-y: scroll;
  overflow: hidden scroll;
  -webkit-overflow-scrolling: touch;
}
.van-overlay {
  background-color: rgba(0, 0, 0, 0.2);
}

.bugOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: none;
  border: 0;
  height: 100%;
  width: 100%;
}
.loginmod {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    margin: 20px 0;
    font-size: 30px;
  }
  button {
    border: 0;
    width: 3rem;
    height: 0.8rem;
    background: #dc3636;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
  }
}
.videoinfo {
  .hint {
    img {
      display: none;
    }
  }
}
</style>
